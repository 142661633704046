import React from 'react';
import './searchbar.css'; // Import the CSS file for styling

function SearchBar() {
  return (
    <div className="search-container">
      <div className="search-bar">
        <label htmlFor="search-input">Kërko:</label>
        <input type="text" id="search-input"/>
      </div>
      <button className="create-button">Krijo</button>
    </div>
  );
}

export default SearchBar;
