import React from 'react';
import './Navbar.css'; // Import a CSS file for styling

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-logo">Cakoni Agro Garancia, kjo do ca gjera mduket</div>
      <button className="navbar-button">LOGIN</button>
    </nav>
  );
}

export default Navbar;
