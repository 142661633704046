import './App.css';
import Navbar from './components/Navbar';
import SearchBar from './components/Searchbar';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <header className="App-header">
        <SearchBar/>
      </header>
    </div>
  );
}

export default App;
//to-do: 1. connecting to a database D1 docs in cloudflare
// 2. create a table in the website an see it filled with data fraom D1 docs
//3. going to possibly need a cloudflare worker to do the connecting or fetching of the data 